import React, { Component } from "react";
import tuzagLogoAllWhite from "../images/tuzag_logo_all_white.svg";

export default class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	tailorData(key, mid, raw = false) {
		try {
			if (this.props.tailorData) {
				if (raw) {
					return this.props.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.props.tailorData[mid][key]
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	}

	render() {
		return (
			<div
				className="footer"
				style={{
					background: "#7faf41",
					border: "none",
					paddingTop: 25,
					paddingBottom: 15
				}}
			>
				<div className="container">
					<div className="row">
						<div className="col-md-4 hidden-xs hidden-sm">
							<p style={{ color: "white", fontSize: 12, paddingTop: 15 }}>
								{this.tailorData("copyright", 4234)}
							</p>
						</div>
						<div className="col-md-4 hidden-xs hidden-sm text-center">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://medium.com/@tuzag"
							>
								<i className="fa fa-rss" style={{ marginLeft: 0 }} />
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://twitter.com/tuzag_inc"
							>
								<i className="fa fa-twitter" />
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/tuzag.inc"
							>
								<i className="fa fa-facebook" />
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.linkedin.com/company/tuzag-inc"
							>
								<i className="fa fa-linkedin" />
							</a>
						</div>
						<div className="col-md-4 text-right hidden-xs hidden-sm">
							<img
								src={tuzagLogoAllWhite}
								style={{ width: "100%", maxWidth: 100 }}
								alt="tuzag logo"
							/>
						</div>

						<div className="col-xs-12 hidden-md hidden-lg text-center">
							<p style={{ color: "white", fontSize: 12, paddingTop: 15 }}>
								{this.tailorData("copyright", 4234)}
							</p>
							<img
								src={tuzagLogoAllWhite}
								style={{ width: "100%", maxWidth: 100 }}
								alt="tuzag logo"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
